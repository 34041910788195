.nav-masthead {
	display: flex;
	align-items: center;
	margin-left: 5%;
	margin-right: 5%;
	flex-wrap: nowrap;
}

.masthead-brand {
	margin-bottom: 0;
}

.nav-masthead .nav-link {
	padding: 0.25rem 0;
	color: rgba(255, 255, 255, 0.5);
	background-color: transparent;
	border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: #fff;
	border-bottom-color: #fff;
}

@media (min-width: 48em) {
	.masthead-brand {
		float: left;
	}
	.nav-masthead {
		float: right;
	}
}

@media screen and (max-width: 768px) {
	.nav-masthead {
		flex-direction: row !important;
	}
}
