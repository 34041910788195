/*Scroll bar Customization*/
.adminFormTabPaper::-webkit-scrollbar {
  width: 0.65em;
}

.adminFormTabPaper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.adminFormTabPaper::-webkit-scrollbar-thumb {
  background-color: rgba(87, 87, 87, 0.733);
  /*outline: 1px solid slategrey;*/
}

.adminFormTabPaper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(169, 169, 169, 0.64);
  outline: none;
}

/*..............................*/