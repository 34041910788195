.profile,
.profile > div {
	border-radius: 4px;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow: hidden;
	width: 100px;

	a {
		margin: auto;
	}
}

.profileClicked {
	box-shadow: var(--globalBoxShadow);
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	backdrop-filter: brightness(0.5);
	align-self: flex-start;
	width: 160px;
	margin-right: 10px;
}
.profileClicked > div {
	display: flex;
	justify-content: center;
	flex-direction: column;
	a {
		margin: auto;
	}
}
